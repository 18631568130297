import {BsLinkedin} from "react-icons/bs";
import {FaGithub} from "react-icons/fa";
import {MdEmail} from "react-icons/md";

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-row">
                <div className="socials">
                    <a href="https://www.linkedin.com/in/ruichenn" target="_blank"><BsLinkedin/></a>
                    <a href="https://github.com/ruiichen" target="_blank"><FaGithub/></a>
                    <a href="mailto:ruicwork@gmail.com" target="_blank"><MdEmail/></a>
                </div>
                <h1>Copyright © Ray Chen 2024</h1>
            </div>
        </div>
    )
}

export default Footer;