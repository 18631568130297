import {BsLinkedin} from 'react-icons/bs';
import {FaGithub} from 'react-icons/fa'
import { MdEmail } from "react-icons/md";
import Collapsible from 'react-collapsible';
import ruiface from '../assets/ruiface.png'

const About = () => {
    return (
        <div className="about" id="about">
            <div className="about_content">
                <div className="row">
                    <div className="column">
                        <img src={ruiface}/>
                    </div>
                    <div className="column">
                        <div className="titlebox">
                            <h2>Get to know</h2>
                            <h1>About Me</h1>
                        </div>
                        <div className="socials">
                            <a href="https://www.linkedin.com/in/ruichenn" target="_blank"><BsLinkedin/></a>
                            <a href="https://github.com/ruiichen" target="_blank"><FaGithub/></a>
                            <a href="mailto:ruicwork@gmail.com" target="_blank"><MdEmail/></a>
                        </div>
                    </div>
                </div>
                <div className="textBox">
                    <h5>
                        Hey there! 👋🏻 I'm Ray, an aspiring software engineer that loves solving problems in math and code. I fell in love with programming not only because I love to innovate and challenge myself, but also, I love to learn about other people's thought processes and how they would tackle a problem. Outside of programming, I love to draw, listen to music, and explore new things. I'm always open to connect and chat!
                    </h5>
                </div>
                <div className="subtitles">
                    <h1>
                        A Timeline of my
                    </h1>
                </div>
                <Collapsible triggerClassName="gen" trigger=" (Sept 2024 — NOW)" >
                    <div className="drawer">
                        <h3>Genesys</h3>
                        <p>
                            I had a work term so good at Genesys that I decided to come back for another! More information to be added later.
                        </p>
                        <p>
                            Currently, I'm working on a WebSocket service that allows the user and the Genesys client to stream audios.
                        </p>
                    </div>
                </Collapsible>
                <Collapsible triggerClassName="gen" trigger=" (May 2024 — August 2024)">
                    <div className="drawer">
                        <h3>Genesys</h3>
                        <p>
                            At Genesys, I primarily worked on a transcript translate feature for the flagship service. It was apart of a microservice based architecture and had several endpoints as deliverables, which was integrated into the existing Genesys Cloud app. Here I learned a lot about software development best practices and how to write clean, testable, and maintainable code.
                        </p>
                        <p>
                            My highlight of the term was building a cache to save customers time during queries, and working on a transcript parser that divides long chunks of strings to be queued for translation. I worked primarily in Python and interacted with many AWS technologies.
                        </p>
                    </div>
                </Collapsible>
                <Collapsible triggerClassName="agf" trigger=" (May 2023 — August 2023)" >
                    <div className="drawer">
                        <h3>AGF Investments</h3>
                        <p>
                            I had my first work-term at AGF Investments, and it was an eye opening experience where I learned a lot about the business side of programming in the industry, as well as what it meant to write code that could withstand the trials of production.
                        </p>
                        <p>
                            My highlight was creating a POC of an email integration service for the software that the company uses to receive documents from clients. It allows clients to send in orders through email instead of only fax. I gave an demo of this service to the head of the department and learned a lot from the experience.
                        </p>
                    </div>
                </Collapsible>
            </div>
        </div>
    )
}

export default About;