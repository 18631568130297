import React from 'react'
import {AiOutlineHome} from 'react-icons/ai'
import {AiOutlineUser} from 'react-icons/ai'
import {BiBook} from 'react-icons/bi'
import {useState} from 'react'

const Navbar = () => {
    const [activeNav, setActiveNav]=useState('#');
    window.addEventListener('load', function() {
        const hdloc = document.getElementById("splash");
        const abtloc = document.getElementById("about");
        const exploc = document.getElementById("projects");
        window.addEventListener('scroll', () => {
            console.log(window.scrollY)
            if(window.scrollY >= hdloc.offsetHeight && window.scrollY < exploc.offsetTop) {
                setActiveNav('#about');
            } else if (window.scrollY >= abtloc.offsetHeight + abtloc.offsetTop) {
                setActiveNav('#projects')
            } else {
                setActiveNav('#');
            }

        })
    })
    return (
        <div className ="navbar">
            <a href="#" onClick={() => setActiveNav('#')} className={activeNav==='#' ? 'active':''}><AiOutlineHome/></a>
            <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav==='#about' ? 'active':''}><AiOutlineUser/></a>
            <a href="#projects" onClick={() => setActiveNav('#projects')} className={activeNav==='#projects' ? 'active':''}><BiBook/></a>
        </div>
    )
}

export default Navbar