import {SiJavascript} from "react-icons/si";
import { FaPython } from "react-icons/fa";
import { SiJupyter } from "react-icons/si";
import { SiPytorch } from "react-icons/si";
import { FaReact } from "react-icons/fa";
import { FaJava } from "react-icons/fa";
import { SiSpring } from "react-icons/si";
import { TbSql } from "react-icons/tb";
import { SiFlask } from "react-icons/si";
import { SiOpencv } from "react-icons/si";
import { FaCss3Alt } from "react-icons/fa";
import { FaHtml5 } from "react-icons/fa";
import formulawon from "../assets/project-thumbnails/formulawon.png"
import wealthshrimple from "../assets/project-thumbnails/wealthshrimple-thumbnail.png"
import facecard from "../assets/project-thumbnails/facecard.png"


const Projects = () => {
    return (
        <div className="projects" id="projects">
            <div className="projects-content">
                <h2>Learn about my</h2>
                <h1>Projects</h1>
                <div className="projects-list">
                    <a href={"https://github.com/ruiichen/formulawon"} target={"_blank"}>
                    <div className="project">
                        <div className="project-row">
                            <div className="project-col tb">
                                <img className="thumbnail" src={formulawon}/>
                            </div>
                            <div className="project-col project-text">
                                <h1>
                                    Formula Won
                                </h1>
                                <div className="techstack">
                                    <a><FaPython/></a>
                                    <a><SiJupyter/></a>
                                    <a><SiPytorch/></a>
                                    <a><FaReact/></a>
                                    <a><SiJavascript/></a>

                                </div>
                                <h2>
                                    Deep learning neural network trained with 20+ years of Formula 1 data to predict the
                                    race winner from qualifying.
                                </h2>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="https://github.com/ruiichen/wealthshrimple" target="_blank">
                    <div className="project">
                        <div className="project-row">
                            <div className="project-col tb">
                                <img className="thumbnail" src={wealthshrimple}/>
                            </div>
                            <div className="project-col project-text">
                                <h1>
                                    WealthShrimple
                                </h1>
                                <div className="techstack">
                                    <a><FaJava/></a>
                                    <a><SiSpring/></a>
                                    <a><TbSql/></a>
                                    <a><FaReact/></a>
                                    <a><SiJavascript/></a>

                                </div>
                                <h2>
                                    Mock paper trading fullstack app that uses NASDAQ stock queries to provide the user
                                    with a trading app experience.
                                </h2>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href={"https://github.com/ruiichen/averagefacegenerator"} target={"_blank"}>
                    <div  target="_blank" className="project">
                        <div className="project-row">
                            <div className="project-col tb">
                                <img className="thumbnail" src={facecard}/>
                            </div>
                            <div className="project-col project-text">
                                <h1>
                                    Average Face Generator
                                </h1>
                                <div className="techstack">
                                    <a><FaPython/></a>
                                    <a><SiFlask/></a>
                                    <a><SiOpencv/></a>
                                    <a><FaCss3Alt/></a>
                                    <a><FaHtml5/></a>
                                </div>
                                <h2>
                                    Given some pictures of faces, generates and displays what the average face between them would look like.
                                </h2>
                            </div>
                        </div>
                    </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Projects;